

.App {
  text-align: left;
}

.App-intro {
  font-size: large;
}


