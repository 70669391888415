
#myMask {
  position: fixed;

  height: 50% auto;
  width: 100%;
  top: 0;
  
 }

 #karuzela {
  margin-top: 10%;
 }
 
 .separ_duzy {
  margin-top: 25%; 
  margin-bottom: 25%;
}


/* szeroki i wysoki - zwykły desktop  */
@media (min-width: 800px) {

  .separ {
    margin-bottom: 10%;
  }

  .App-header {
    max-width: 100%;
    background-color: #EBEEC4;
    padding: 1%;
    color: #171C5C;
    top: 0;
    position: fixed;
    height: 5% auto;
    width: 100%;
    z-index: 100;
    align-items: center;
    text-align: center;
    display: flex;
    flex-wrap: wrap;  /* optional. only if you want the items to wrap */
  }

    
  .App-header-title {
    max-width: 100%;
    position: relative;
    left: auto;
    width: auto;
    height: auto;
    text-align: center;
    text-transform: uppercase;
    display: flex;
    flex-wrap: wrap;
    font-family: "Quicksand", "Source Sans Pro", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif; /* 1 */
    font-weight: 600;
    color: #35495e;
    letter-spacing: 1px;
    font-size: 1.2em;
  }
    
  .App-header-contact {
    max-width: 100%;
    position: relative;
    margin-left:auto; 
    margin-right:2%;
    height: auto;
    flex-wrap: wrap;  /* optional. only if you want the items to wrap */
    top: 0 auto ;
    right:0  auto;
    text-align: left;
    display: flex;
    font-family: "Quicksand", "Source Sans Pro", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif; /* 1 */
    font-weight: 500;
    color: #35495e;
    letter-spacing: 1px;
    font-size:1.2em;
  }
    
    
  .App-header-buttons {
    max-width: 100%;
    align-items: center;
    position: relative;
    bottom: 0;
    left: 0 ;
    height: auto;
    width: 100%;
    flex-wrap: wrap;
    padding-top: 1px;
  }
    
  .button1 {
    max-width: 100%;
    background-color: white;
    color: black;
    border: 1px solid #4CAF50; /* Green */
    width:  20% ;
    height: auto;
    border-radius: 60px;
    font-size:1em;
    padding: 5px 10px;
    margin-left: 5px;
  }
    
  .button1:hover {
    max-width: 100%;
    background-color: #3e8e41;
    color: #fff;
  }

  .button1:active {
    max-width: 100%;
    background-color: #3e8e41;
    box-shadow: 0 50% #666;
    border-radius: 60px;
    transform: translateY(20%);
  }

  .button2 {
    max-width: 100%;
    background-color: white;
    color: black;
    border: 1px solid #4CAF50; /* Green */
    width:  40%;
    height: auto;
    border-radius: 60px;
    font-size:1em;
    padding: 5px 10px;
    margin-left: 5px;
  }
    
  .button2:hover {
    max-width: 100%;
    background-color: #3e8e41;
    color: #fff;
  }

  .button2:active {
    max-width: 100%;
    background-color: #3e8e41;
    box-shadow: 0 50% #666;
    border-radius: 60px;
    transform: translateY(20%);
  }
}

/* waski i niski  - mobile vertical */
@media (max-width: 800px)   {

  .separ {
    margin-bottom: 25%;
  }

  .App-header {
    max-width: 100%;
    background-color: #EBEEC4;
    padding: 1%;
    color: #171C5C;
    top: 0;
    position: fixed;
    height: 5% auto;
    width: 100%;
    z-index: 100;
    align-items: center;
    text-align: center;
    display: flex;
    flex-wrap: wrap;  /* optional. only if you want the items to wrap */
  }

    
  .App-header-title {
    max-width: 100%;
    position: relative;
    left: auto;
    width: auto;
    height: auto;
    text-align: center;
    text-transform: uppercase;
    display: flex;
    flex-wrap: wrap;
    font-family: "Quicksand", "Source Sans Pro", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif; /* 1 */
    font-weight: 600;
    color: #35495e;
    
    font-size: 0.6em;
  }
    
  .App-header-contact {
    max-width: 100%;
    position: relative;
    margin-left:auto; 
    margin-right:2%;
    height: auto;
    flex-wrap: wrap;  /* optional. only if you want the items to wrap */
    top: 0 auto ;
    right:17% auto;
    text-align: left;
    display: flex;
    font-family: "Quicksand", "Source Sans Pro", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif; /* 1 */
    font-weight: 500;
    color: #35495e;
    
    font-size:0.8em;
  }
    
    
  .App-header-buttons {
    max-width: 100%;
    align-items: center;
    position: relative;
    bottom: 0;
    left: 0 ;
    height: auto;
    width: 100%;
    flex-wrap: wrap;
    padding-top: 1px;
  }
    
  .button1 {
    max-width: 100%;
    background-color: white;
    color: black;
    border: 1px solid #4CAF50; /* Green */
    width:  15% ;
    height: auto;
    border-radius: 60px;
    font-size:0.6em;
    padding: 0.5% 1%;
    margin-left: 1%;
  }
    
  .button1:hover {
    max-width: 100%;
    background-color: #3e8e41;
    color: #fff;
  }

  .button1:active {
    max-width: 100%;
    background-color: #3e8e41;
    box-shadow: 0 50% #666;
    border-radius: 60px;
    transform: translateY(20%);
  }

  .button2 {
    max-width: 100%;
    background-color: white;
    color: black;
    border: 1px solid #4CAF50; /* Green */
    width:  40%;
    height: auto;
    border-radius: 60px;
    font-size:0.6em;
    padding: 5px 10px;
    margin-left: 5px;
  }
    
  .button2:hover {
    max-width: 100%;
    background-color: #3e8e41;
    color: #fff;
  }

  .button2:active {
    max-width: 100%;
    background-color: #3e8e41;
    box-shadow: 0 50% #666;
    border-radius: 60px;
    transform: translateY(20%);
  }
}

