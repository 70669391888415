#before_footer {
  margin-bottom: 40%;
 }

 .footer-kanc-text {
  letter-spacing: 1px;
  font-family: "Quicksand", "Source Sans Pro", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif; /* 1 */
  font-weight: bold;
  font-size: 1.2em;
}  

/* szeroki i wysoki - zwykły desktop  */
@media (min-width: 800px)  {

  .App-footer {
    max-width: 100%;
    background-color:  white;
    position: fixed;
    bottom: 0;
    left: 0;
    height: 10%;
    width: 100%;
    text-align: center;
    /* align-items: center; /* for vertical alignment */ 
    /* flex-wrap: wrap;  optional. only if you want the items to wrap */
    /* justify-content: center; /* for horizontal alignment */
  }

  /* lewa */
  .App-footer-kirp {
    max-width: 100%;
    height: 8%;
    width: 25%; 
    position: fixed;
    bottom: 0;
    left: 0;
  }
  .kirp-logo{
    max-width: 100%;
    background-color: #FFFFFF;
    height: 10%;
    position: fixed;
    bottom: 0;
    left: 15%;
  }

  /* srodek */
  .App-footer-vcc {
    max-width: 100%;
    height: 10%;
    position: fixed;
    bottom: 0;
    left: 45%;
  }

  .vcc-logo{
    max-width: 100%;
    background-color: #FFFFFF;
    height: 10%;
    
    position: fixed;
    bottom: 0;  
  }

  /* prawa */
  .App-footer-kanc {
    max-width: 100%;
    position: fixed;
    height: 8%;
    width: 25%;
    bottom: 0;
    right: 10%;
    letter-spacing: 1px;
    font-family: "Quicksand", "Source Sans Pro", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif; /* 1 */
    font-weight: 600;
    font-size: 2 vw;
  }

  .footer-kanc-text {
    max-width: 100%;
    letter-spacing: 1px;
    font-family: "Quicksand", "Source Sans Pro", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif; /* 1 */
    font-weight: bold;
    font-size: 1.2em;
  }   
}

/* waski i niski  - mobile vertical */
@media (max-width: 800px) {
.App-footer {
  max-width: 100%;
  background-color:  white;
  position: fixed;
  bottom: 0;
  left: 0;
  height: 10%;
  width: 100%;
  text-align: center;
  /* align-items: center; /* for vertical alignment */ 
  /* flex-wrap: wrap;  optional. only if you want the items to wrap */
  justify-content: center; /* for horizontal alignment */
}

/* lewa */
.App-footer-kirp {
  max-width: 100%;
  float: left;
  height: 8%;
  width: auto; 
  position: fixed;
  bottom: 0;
  left: 0;
}
.kirp-logo{
  max-width: 100%;
  background-color: #FFFFFF;
  height: 8%;
  width: auto;
  position: fixed;
  bottom: 0;
  left: 0%;
  
}

/* srodek */
.App-footer-vcc {
  max-width: 100%;
  height: 5%;
  width: auto;
  position: fixed;
  bottom: 0;
  left: 35%;
}

.vcc-logo{
  max-width: 100%;
  background-color: #FFFFFF;
  height: 5%;
  width: auto;
  position: fixed;
  bottom: 0;  
}

/* prawa */
.App-footer-kanc {
  max-width: 100%;
  position: fixed;
  height: 5%;
  width: auto;
  bottom: 2%;
  right: 2%;
  
  font-family: "Quicksand", "Source Sans Pro", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif; /* 1 */
  font-weight: 600;
  font-size: 2vw;
}

.footer-kanc-text {
  max-width: 100%;
  position: fixed;
  right: 10%;
  font-family: "Quicksand", "Source Sans Pro", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif; /* 1 */
  font-weight: bold;
  font-size: 0.8em;
}
}