@media (max-width: 800px)  {
   .leftPanelKontakt {
       float: none;
       width: 100%;
       max-width: 100%;
   
   }

     
   .rightPanelKontakt {
       float: none;
       width: 100%;
       max-width: 100%;
   }

   .cookies_panel {
       max-width: 100%;
       position: fixed;
       background-color: rgb(206, 235, 194);
       border-radius: 60px;
       border: 3px solid rgb(85, 131, 88); /* Green */
       bottom: 10%;
       margin-left: 1%;
       text-align: center;
       padding: 5px 10px;
       width: 100%;
       letter-spacing: 1px;
       font-family: "Quicksand", "Source Sans Pro", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
       font-size: small;
   }

   .masz_prawo {
       width: 50%;
       max-width: 100%;
   }
   
}

@media (min-width: 800px)  {
   .leftPanelKontakt {
       float: left;
       height: 410px;
       width: 50%;
       max-width: 100%;
   }
   
   .rightPanelKontakt {
       float: right;
       height: 410px;
       width: 50%;
       max-width: 100%;

   }

   .cookies_panel {
       max-width: 100%;
       position: fixed;
       background-color: rgb(206, 235, 194);
       border-radius: 60px;
       border: 3px solid rgb(85, 131, 88); /* Green */
       bottom: 10%;
       margin-left: 1%;
       text-align: center;
       padding: 5px 10px;
       width: 50%;
       letter-spacing: 1px;
       font-family: "Quicksand", "Source Sans Pro", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
       font-size: normal;
   }

   .masz_prawo {
       width: 25%;
       max-width: 100%;
   }
   
}



.MainPanel {
 text-align: justify;
 margin: 5% 5% 2% 5%;
 max-width: 100%;
}

.kontaktPanel {
   max-width: 100%;
   text-align: center;
   width: 100%;
   letter-spacing: 1px;
 font-family: "Quicksand", "Source Sans Pro", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
 font-size:normal;
}

.mpPanel {
   max-width: 100%;
   text-align: center;
   width: 100%;
   letter-spacing: 1px;
 font-family: "Quicksand", "Source Sans Pro", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
 font-size:1.2em;
 margin-top: 10%;
 margin-bottom: 15%;
}

.katarzynaPanel {
   max-width: 100%;
   margin-left: 0;
   margin-top: 25%;
   margin-bottom: 6%;
   margin-right: 5%;
   text-align: left;
   width: 100%;
   letter-spacing: 1px;
 font-family: "Quicksand", "Source Sans Pro", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
 font-size:normal;
}


.tytul {
   max-width: 100%; 
  text-align: center;
  text-decoration: underline;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  line-height: 1.8;
  font-weight: normal;
  font-size: large; 
}  

.tytul-duzy-odstep {
   max-width: 100%;
   text-align: center;
   text-decoration: underline;
   text-transform: uppercase;
   letter-spacing: 0.05em;
   line-height: 1.8;
   font-weight: normal;
   font-size: normal;
   margin-top: 15%; 
}  

.tytul1 {
   max-width: 100%;
   text-align: center;
   text-decoration: underline;
   text-transform: capitalize;
   letter-spacing: 0.05em;
   line-height: 1.8;
   font-size: large; 
   margin-top: 3%;
} 


.tytul-opis {
   max-width: 100%;
   text-align: justify;
   letter-spacing: 0.05em;
   line-height: 1.5;
   font-style: normal;
   font-size: normal;
   margin-top: 3%; 
}

.tytul-opis-przepisy {
   max-width: 100%;
   text-align: justify;
   letter-spacing: 0.05em;
   line-height: 1.5;
   font-style: normal;
   font-size: normal;
   margin-top: 3%; 
   margin-bottom: 3%;
}

.tytul-opis-maly-odstep-gora {
   text-align: justify;
   letter-spacing: 0.05em;
   line-height: 1.5;
   font-style: normal;
   font-size: normal;
   margin-top: 1%; 
   max-width: 100%;
}


.tytul-opis-center-green {
   text-align: center;
   letter-spacing: 0.05em;
   line-height: 1.5;
   font-style: normal;
   font-size: normal;
   font-weight: bold;
   color: green;
   margin-top: 5%;
   margin-bottom: 5%;
   margin-left: 5%;
   margin-right: 5%;
   max-width: 100%;
}


.tytul-opis-center-green1 {
   text-align: center;
   letter-spacing: 0.05em;
   line-height: 1;
   font-style: normal;
   font-size: normal;
   font-weight: bold;
   color: green;
   margin-top: 1%;
   margin-bottom: 1%;
   margin-left: 5%;
   margin-right: 5%;
   max-width: 100%;
}

.tytul-opis-justify-green {
   text-align: center;
   letter-spacing: 0.05em;
   line-height: 1.5;
   font-style: normal;
   font-size: normal;
   font-weight: bold;
   color: green; 
   margin-top: 5%;
   margin-bottom: 5%;
   max-width: 100%;
}

.tytul-opis-left-green {
   text-align: left;
   letter-spacing: 0.05em;
   line-height: 1.5;
   font-style: normal;
   font-size: normal;
   font-weight: bold;
   color: green; 
   margin-top: 5%;
   margin-bottom: 0%;
   max-width: 100%;
}

.opis1 {
   text-align: justify;
   letter-spacing: 0.05em;
   line-height: 1.5em;
   font-style: normal;
   font-size: normal;
   margin: 2% 0% 2% 0%;
   max-width: 100%;  
}



sub, sup {vertical-align: baseline; position: relative; font-size: 70%;} /* 70% size of its parent element font-size which is good. */
sub {bottom: -0.6em;} /* use em becasue they adapt to parent font-size */
sup {top: -0.6em;} /* use em becasue they adapt to parent font-size */

.opis_pogrubiony {
   text-align: justify;
   letter-spacing: 0.05em;
   line-height: 1.5em;
   font-style: normal;
   font-weight: bold;
   font-size: normal;
   margin: 2% 0% 2% 0%;
   max-width: 100%;  
}

.opis_pogrubiony_srodek {
   text-align: center;
   letter-spacing: 0.05em;
   line-height: 1.5em;
   font-style: normal;
   font-weight: bold;
   font-size: normal;
   margin: 2% 0% 2% 0%;
   max-width: 100%;  
}

.opis2 {
   text-align: justify;
   letter-spacing: 0.05em;
   line-height: 1.5em;
   font-style: normal;
   font-size: normal;
   margin: 2% 0% 2% 0%;  
   max-width: 100%;
}

.sekcja {
   text-align: center;
   text-transform: uppercase;
   letter-spacing: 0.05em;
   line-height: 1.8;
   font-style: bold;
   font-size: normal;
   color: green;
   margin: 2% 0% 2% 0%; 
   border: 0.2em solid green;
   outline-style: single;
   outline-color: green;
   max-width: 100%;
} 

ul.checkmark {
   list-style-type: none;
   list-style-image: url(data:image/gif;base64,R0lGODlhIgAlAHAAACH5BAEAAPwALAAAAAAiACUAhwAAAAAAMwAAZgAAmQAAzAAA/wArAAArMwArZgArmQArzAAr/wBVAABVMwBVZgBVmQBVzABV/wCAAACAMwCAZgCAmQCAzACA/wCqAACqMwCqZgCqmQCqzACq/wDVAADVMwDVZgDVmQDVzADV/wD/AAD/MwD/ZgD/mQD/zAD//zMAADMAMzMAZjMAmTMAzDMA/zMrADMrMzMrZjMrmTMrzDMr/zNVADNVMzNVZjNVmTNVzDNV/zOAADOAMzOAZjOAmTOAzDOA/zOqADOqMzOqZjOqmTOqzDOq/zPVADPVMzPVZjPVmTPVzDPV/zP/ADP/MzP/ZjP/mTP/zDP//2YAAGYAM2YAZmYAmWYAzGYA/2YrAGYrM2YrZmYrmWYrzGYr/2ZVAGZVM2ZVZmZVmWZVzGZV/2aAAGaAM2aAZmaAmWaAzGaA/2aqAGaqM2aqZmaqmWaqzGaq/2bVAGbVM2bVZmbVmWbVzGbV/2b/AGb/M2b/Zmb/mWb/zGb//5kAAJkAM5kAZpkAmZkAzJkA/5krAJkrM5krZpkrmZkrzJkr/5lVAJlVM5lVZplVmZlVzJlV/5mAAJmAM5mAZpmAmZmAzJmA/5mqAJmqM5mqZpmqmZmqzJmq/5nVAJnVM5nVZpnVmZnVzJnV/5n/AJn/M5n/Zpn/mZn/zJn//8wAAMwAM8wAZswAmcwAzMwA/8wrAMwrM8wrZswrmcwrzMwr/8xVAMxVM8xVZsxVmcxVzMxV/8yAAMyAM8yAZsyAmcyAzMyA/8yqAMyqM8yqZsyqmcyqzMyq/8zVAMzVM8zVZszVmczVzMzV/8z/AMz/M8z/Zsz/mcz/zMz///8AAP8AM/8AZv8Amf8AzP8A//8rAP8rM/8rZv8rmf8rzP8r//9VAP9VM/9VZv9Vmf9VzP9V//+AAP+AM/+AZv+Amf+AzP+A//+qAP+qM/+qZv+qmf+qzP+q///VAP/VM//VZv/Vmf/VzP/V////AP//M///Zv//mf//zP///wAAAAAAAAAAAAAAAAiQAPcJHEiwoMGDCBMqXMiwocOHECNKnEixosWLGDNq3Mixo8ePIEOKHEgLAAABHNmZNBlno8qVLTW+BBDglMuVAGJmzKcC5kZ9VHxqBLrSyE9CK4FwLGkSQsVdJhNEEzjzwdSJTFnum9fz5NWJPFc+gIaUZqiLWXHmxMhVLQCnGdMCsCoTZ4CzQ4OaVDqyL8WAADs=);
   list-style-position:inside;
   padding-left: 5%;
   max-width: 100%;
}
ul.checkmark1 {
   list-style-type: none;
   list-style-image: url(data:image/gif;base64,R0lGODlhIgAlAHAAACH5BAEAAPwALAAAAAAiACUAhwAAAAAAMwAAZgAAmQAAzAAA/wArAAArMwArZgArmQArzAAr/wBVAABVMwBVZgBVmQBVzABV/wCAAACAMwCAZgCAmQCAzACA/wCqAACqMwCqZgCqmQCqzACq/wDVAADVMwDVZgDVmQDVzADV/wD/AAD/MwD/ZgD/mQD/zAD//zMAADMAMzMAZjMAmTMAzDMA/zMrADMrMzMrZjMrmTMrzDMr/zNVADNVMzNVZjNVmTNVzDNV/zOAADOAMzOAZjOAmTOAzDOA/zOqADOqMzOqZjOqmTOqzDOq/zPVADPVMzPVZjPVmTPVzDPV/zP/ADP/MzP/ZjP/mTP/zDP//2YAAGYAM2YAZmYAmWYAzGYA/2YrAGYrM2YrZmYrmWYrzGYr/2ZVAGZVM2ZVZmZVmWZVzGZV/2aAAGaAM2aAZmaAmWaAzGaA/2aqAGaqM2aqZmaqmWaqzGaq/2bVAGbVM2bVZmbVmWbVzGbV/2b/AGb/M2b/Zmb/mWb/zGb//5kAAJkAM5kAZpkAmZkAzJkA/5krAJkrM5krZpkrmZkrzJkr/5lVAJlVM5lVZplVmZlVzJlV/5mAAJmAM5mAZpmAmZmAzJmA/5mqAJmqM5mqZpmqmZmqzJmq/5nVAJnVM5nVZpnVmZnVzJnV/5n/AJn/M5n/Zpn/mZn/zJn//8wAAMwAM8wAZswAmcwAzMwA/8wrAMwrM8wrZswrmcwrzMwr/8xVAMxVM8xVZsxVmcxVzMxV/8yAAMyAM8yAZsyAmcyAzMyA/8yqAMyqM8yqZsyqmcyqzMyq/8zVAMzVM8zVZszVmczVzMzV/8z/AMz/M8z/Zsz/mcz/zMz///8AAP8AM/8AZv8Amf8AzP8A//8rAP8rM/8rZv8rmf8rzP8r//9VAP9VM/9VZv9Vmf9VzP9V//+AAP+AM/+AZv+Amf+AzP+A//+qAP+qM/+qZv+qmf+qzP+q///VAP/VM//VZv/Vmf/VzP/V////AP//M///Zv//mf//zP///wAAAAAAAAAAAAAAAAiQAPcJHEiwoMGDCBMqXMiwocOHECNKnEixosWLGDNq3Mixo8ePIEOKHEgLAAABHNmZNBlno8qVLTW+BBDglMuVAGJmzKcC5kZ9VHxqBLrSyE9CK4FwLGkSQsVdJhNEEzjzwdSJTFnum9fz5NWJPFc+gIaUZqiLWXHmxMhVLQCnGdMCsCoTZ4CzQ4OaVDqyL8WAADs=);
   list-style-position:inside;
   padding-left: 50%;
   max-width: 100%;
}

ul.postepowanie {
   list-style-type: none;
   list-style-position:inside;
   padding-left: 5%;
   max-width: 100%;
}

ol.counterek {
   counter-reset: foo;
   display: table;
   max-width: 100%;
}

li.counterek {
   list-style: none;
   counter-increment: foo;
   display: table-row;
   max-width: 100%;
}

li.counterek::before {
   content: counter(foo) ")";
   display: table-cell;
   text-align: right;
   padding-right: .3em;
   max-width: 100%;
}

ol.counterek1 {
   counter-reset: foo;
   display: table;
   max-width: 100%;
}

li.counterek1 {
   list-style: none;
   counter-increment: foo;
   display: table-row;
   max-width: 100%;
}

li.counterek1::before {
   content: counter(foo) ")";
   display: table-cell;
   text-align: right;
   padding-right: .3em;
   max-width: 100%;
}

/* cookies panelek  */

.hidden {
   max-width: 100%;
   visibility: hidden;
}

