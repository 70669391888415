
.Body {
  text-align: left;}

.gallery-image{
    max-width: auto;
    min-width: auto;
    width: auto;
    height: 80%;
    max-height: 80%;
    background-color: rgb(253, 255, 254);
    align-content: center;
  }

  .tescik {
    display: inline-block;
    width: calc(80vw)
  }

  .generalPanel {
    background-color: white; /* for vizualization purposes */
    text-align: center;}
  
  .carousel-inner > .item > img, 
  .carousel-inner > .item > a > img{
  /*width: 100%; /* use this, or not */
  margin: auto;
  }
  
  /* START OF Change the font/size/color of the Slider Titles */
  /* Change Slider Titles (2em) font-size, (Garamond) family, (red) color */
  /* Title text */
  .carousel-caption h3 {
    /*font-family:    Garamond; */
    font-size:      3em; 
    color:          #EAEEF1 !important;
    }
    /* Description text */
    .carousel-caption p {
    /*font-family:    Garamond; */
     font-size:      2em; 
    color:          #E5EEF5 !important;
    }
    /* Button text */
    .carousel-caption .btn {
    font-family:    Garamond;
    font-size:      1em;
    color:          blue;
    }
    /* END OF Change the font/size/color of the Slider Titles */